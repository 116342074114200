<template>
  <b-container
    fluid
  >
    <b-row class="mb-4">
      <b-col
        cols="12"
      >
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="p-2"
            order="3"
            order-md="4"
          >
            <PaleCard
              label="Available Balance"
              variant="primary"
              :count="account.available_balance"
              :loading="fetching"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="p-2"
            order="3"
            order-md="4"
          >
            <PaleCard
              label="Today's Total Disbursement"
              variant="info"
              :count="account.total_today"
              :loading="fetching"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="p-2"
            order="3"
            order-md="4"
          >
            <PaleCard
              label="Yesterday's Total Disbursement"
              variant="warning"
              :count="account.total_yesterday"
              :loading="fetching"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { SMerchantDashboard } from '@/services'
import PaleCard from '@/components/custom/PaleCard'
import formatter from '@/mixins/formatter'

export default {

  name: 'MerchantDashboard',

  middleware: ['auth', 'merchant'],

  components: {
    PaleCard
  },

  mixins: [formatter],

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data () {
    return {
      fetching: false,
      account: {
        available_balance: 0,
        total_today: 0,
        total_yesterday: 0
      }
    }
  },

  mounted () {
    core.index()
    this.getStatistics()
  },

  methods: {
    getStatistics () {
      this.fetching = true
      SMerchantDashboard.get().then(({ data }) => {
        this.account.available_balance = this.numberFormatter(data.available_balance)
        this.account.total_today = this.numberFormatter(data.total_today)
        this.account.total_yesterday = this.numberFormatter(data.total_yesterday)
      }).finally(() => {
        this.fetching = false
      })
    }
  }
}
</script>
